// src/features/package/packageSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NewPackage, Package, PackageState } from "data/types";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: PackageState = {
  packages: [],
  loading: false,
  error: null,
};

// Assuming your backend endpoints follow similar patterns to the user endpoints
export const fetchPackages = createAsyncThunk<
  Package[],
  undefined,
  { rejectValue: string }
>("packages/fetchPackages", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/package");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createPackage = createAsyncThunk<
  Package,
  NewPackage,
  { rejectValue: string }
>("packages/createPackage", async (packageData, { rejectWithValue }) => {
  try {
    // Retrieve the token from localStorage

    // Inside your createPackage thunk
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Access token from localStorage
      },
    };
    const response = await axiosInstance.post(
      "/api/package",
      packageData,
      config
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

// Add this thunk to your packageSlice.ts
export const deletePackage = createAsyncThunk<
  string, // Assuming the backend returns the ID of the deleted package as confirmation
  string, // The type of the payload is the ID of the package to delete
  { rejectValue: string }
>("packages/deletePackage", async (packageId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/api/package/${packageId}`,
      config
    );
    return packageId; // Return the ID of the deleted package
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});
export const editPackage = createAsyncThunk<
  Package,
  { packageId: string; data: NewPackage },
  { rejectValue: string }
>(
  "packages/editPackage",
  async ({ packageId, data }, { rejectWithValue, dispatch }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      await axiosInstance.put(`/api/package/${packageId}`, data, config);
      // Assuming the PUT request doesn't return the updated object,
      // you fetch the updated package here.
      const updatedPackageResponse = await axiosInstance.get<Package>(
        `/api/package/${packageId}`,
        config
      );
      return updatedPackageResponse.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPackages.fulfilled,
        (state, action: PayloadAction<Package[]>) => {
          state.loading = false;
          state.packages = action.payload;
        }
      )
      .addCase(
        fetchPackages.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
          console.error("Error fetching packages:", state.error); // Log error
        }
      )
      

      .addCase(
        createPackage.fulfilled,
        (state, action: PayloadAction<Package>) => {
          // Optionally update state to include the new package
          state.packages.push(action.payload);
        }
      )

      // Inside extraReducers of your packageSlice
      .addCase(
        deletePackage.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.packages = state.packages.filter(
            (packageItem) => packageItem._id !== action.payload
          );
        }
      )
      .addCase(
        editPackage.fulfilled,
        (state, action: PayloadAction<Package>) => {
          // Find the index of the package that was edited
          const index = state.packages.findIndex(
            (packageItem) => packageItem._id === action.payload._id
          );
          if (index !== -1) {
            // Update the package at the found index with the new data
            state.packages[index] = action.payload;
          }
        }
      );
    // Handle pending and rejected cases as needed

    // Handle pending and rejected cases as needed
  },
});

export default packageSlice.reducer;
