import BgGlassmorphism from "./BgGlassmorphism";
import SectionHero from "components/SectionHero";
import CarCard from "components/CarCard";
import PackageCard from "components/PackageCard";
import AdsCard from "./AdsCard";
import Ads from "./Ads";
import { CarType, PackageType } from "data/types";
import sedan from "../images/cars/sedan.png";
import suv from "../images/cars/suv.png";
import pickup from "../images/cars/pickup.png";
import { useEffect, useState } from "react";
import { fetchPackages } from "features/package/packageSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

export interface Package {
  _id: string;
  name: string;
  price: number;
  details: Detail[];
  carType: string;
}

export type Detail = {
  title: string;
  content: string;
};

const CarTypes: CarType[] = [
  {
    id: "1",
    name: "Sedan",
    isSelected: true,
    thumbnail: sedan,
  },
  {
    id: "2",
    name: "SUV",
    isSelected: false,
    thumbnail: suv,
  },
  {
    id: "3",
    name: "Pickup",
    isSelected: false,
    thumbnail: pickup,
  },
];

function PageHome() {
  const dispatch = useAppDispatch();
  const { packages, loading, error } = useAppSelector(
    (state) => state.packages
  );

  // Initialize selectedCar with the first car type ('Sedan') if it exists
  const initialCarType = CarTypes.find((car) => car.id === "1");
  const [selectedCar, setSelectedCar] = useState<CarType | undefined>(
    initialCarType
  );
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  useEffect(() => {
    dispatch(fetchPackages())
    //const currentPackages = useAppSelector((state) => state.packages);
    //console.log("Current packages in state:", currentPackages.packages); // Log current state

  }, [dispatch]);


  const filteredPackages = Array.isArray(packages)
    ? packages.filter(pkg => pkg.carType === selectedCar?.name)
    : [];

  const handleCarSelect = (car: CarType) => {
    setSelectedCar(car);
  };

  const handlePackageSelect = (pkg: Package) => {
    setSelectedPackage(
      selectedPackage && selectedPackage._id === pkg._id ? null : pkg
    );
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* SECTION HERO */}
      <div className="container px-1 sm:px-4 mb-24 ">
        <SectionHero className="mb-24 mt-4" />
        <div className="text-center">
          <h2 className="text-lg lg:text-4xl text-white font-semibold mt-4 mb-4">
            Select Your Car Type.
          </h2>
        </div>
        {/* SECTION 1 */}
        <div className="grid grid-cols-12 gap-6 lg:h-80">
          {CarTypes.map((car) => (
            <div
              key={car.id}
              className=" col-span-12 sm:col-span-4 lg:col-span-4 flex"
            >
              <div className="mx-auto w-4/5">
                <CarCard
                  taxonomy={car}
                  isSelected={car === selectedCar}
                  onSelect={() => handleCarSelect(car)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <h2 className="text-lg lg:text-4xl text-white font-semibold mt-4 mb-4">
            Select Your Package.
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
          {filteredPackages.map((pkg) => (
            <div
              key={pkg._id}
              className="w-full"
            >
              <PackageCard
                taxonomy={pkg}
                isSelected={selectedPackage?._id === pkg._id}
                onSelect={() => handlePackageSelect(pkg)}
                className="hover:shadow-2xl hover:shadow-amber-700 transition-all duration-300"
              />
            </div>
          ))}
        </div>

        <div className="text-center">
          <h2 className="text-lg lg:text-4xl text-white font-semibold mt-16 mb-8">
            Advertisements
          </h2>

          <Ads />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
