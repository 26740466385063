// Blog page
import React from "react";
import BlogCard from "./BlogCard"; // Adjust the path according to your file structure
import blogs from "../data/blogs"; // Import centralized data

const Blog = () => {
  return (
    <div className="container mx-auto px-4 py-12 min-h-screen">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-white mb-4">Our Blog</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          Discover the latest insights, tips, and stories about car detailing and maintenance
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogs.map((blog) => (
          <BlogCard
            key={blog.id}
            id={blog.id}
            title={blog.title}
            summary={blog.summary}
            imageUrl={blog.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default Blog;
