import React, { FC, useEffect } from "react";
import AdsCard, { Ad } from "./AdsCard";
import { fetchActiveAds } from "../features/ads/adsSlice"; // Make sure this slice exists
import { useAppDispatch, useAppSelector } from "app/hooks";

const Ads: FC = () => {
  const dispatch = useAppDispatch();
  const { ads, loading, error } = useAppSelector((state) => state.ads);

  useEffect(() => {
    dispatch(fetchActiveAds());
  }, [dispatch]);
  if (loading) return <div>Loading ads...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {Array.isArray(ads) && ads.map((ad) => (
        <AdsCard key={ad._id} ad={ad} />
      ))}
      </div>
    </div>
  );
};

export default Ads;
