// components/BlogPost.jsx
import React from "react";
import { useParams, Link } from "react-router-dom";
import blogs from "../data/blogs";

const BlogPost = () => {
  let { id } = useParams();
  const blog = blogs.find((blog) => blog.id.toString() === id);

  if (!blog) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Blog Post Not Found</h2>
          <Link to="/blog" className="text-blue-400 hover:text-blue-300 inline-flex items-center">
            ← Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-neutral-900">
      <article className="max-w-4xl mx-auto px-4 py-16">
        {/* Back Button */}
        <Link
          to="/blog"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 mb-8 transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Back to Blog
        </Link>

        {/* Header Section */}
        <header className="mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 text-white leading-tight">
            {blog.title}
          </h1>

          {/* Featured Image */}
          <div className="relative rounded-xl overflow-hidden shadow-2xl">
            <img
              src={blog.imageUrl}
              alt={blog.title}
              className="w-full h-[400px] object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-neutral-900/60 to-transparent" />
          </div>
        </header>

        {/* Content Section */}
        <div className="prose prose-lg max-w-none">
          {blog.content.split("\n").map((paragraph, index) => (
            <p key={index} className="text-gray-300 leading-relaxed mb-6 text-lg">
              {paragraph}
            </p>
          ))}
        </div>
      </article>
    </div>
  );
};

export default BlogPost;
