// src/features/ads/adsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Ad, NewAd, AdsState } from "data/types";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: AdsState = {
  ads: [],
  loading: false,
  error: null,
};

export const fetchAds = createAsyncThunk<
  Ad[],
  undefined,
  { rejectValue: string }
>("ads/fetchAds", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/ads");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const fetchActiveAds = createAsyncThunk<
  Ad[],
  undefined,
  { rejectValue: string }
>("ads/fetchActiveAds", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/ads/active");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createAd = createAsyncThunk<Ad, NewAd, { rejectValue: string }>(
  "ads/createAd",
  async (adData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axiosInstance.post("/api/ads", adData, config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

export const deleteAd = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>("ads/deleteAd", async (adId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const adResponse = await axiosInstance.delete(`/api/ads/${adId}`, config);
    const adData = adResponse.data;
    console.log(adData)
    // Extract the public ID from the image URL
    const imageUrl = adData.image; // Adjust this if your image field has a different name
    const publicId = imageUrl.split('/').pop().split('.')[0]; // This will extract the public ID from the URL
    console.log("Extracted public ID:", publicId);

    // Now delete the service from the database
    const deleteResponse = await axiosInstance.delete(`/api/ads/${adId}`, config);
    
    if (deleteResponse.status === 200) {
      // Delete the image from Cloudinary by calling your backend API
      const deleteImageResponse = await axiosInstance.post(
        `/api/delete-image`,
        { publicId },
        config
      );

      // Check if the image deletion was successful
      if (deleteImageResponse.data.success) {
        return adId; // Return the ID of the deleted service
      } else {
        return rejectWithValue("Image deletion failed.");
      }
    } else {
      return rejectWithValue("Service deletion failed.");
    }
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const editAd = createAsyncThunk<
  string,
  { adId: string; data: NewAd },
  { rejectValue: string }
>("ads/editAd", async ({ adId, data }, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axiosInstance.put(`/api/ads/${adId}`, data, config);
    return adId; // Return the ID of the edited ad
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

const adsSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAds.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAds.fulfilled, (state, action: PayloadAction<Ad[]>) => {
        state.loading = false;
        state.ads = action.payload;
      })
      .addCase(
        fetchAds.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )
      .addCase(fetchActiveAds.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchActiveAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = action.payload;
      })
      .addCase(fetchActiveAds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch Ads";
      })

      .addCase(createAd.fulfilled, (state, action: PayloadAction<Ad>) => {
        state.ads.push(action.payload);
      })
      .addCase(deleteAd.fulfilled, (state, action: PayloadAction<string>) => {
        state.ads = state.ads.filter((adItem) => adItem._id !== action.payload);
      })
      .addCase(editAd.fulfilled, (state, action: PayloadAction<string>) => {});
  },
});

export default adsSlice.reducer;
