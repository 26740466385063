import React, { FC } from "react";

export interface Ad {
  _id: string;
  title: string;
  image: string;
  link: string;
}

export interface AdsCardProps {
  ad: Ad;
  className?: string;
}

const AdsCard: FC<AdsCardProps> = ({ ad, className = "" }) => {
  return (
    <div
      className={`relative flex flex-col justify-between items-center w-full h-full rounded-2xl overflow-hidden shadow-lg ${className}`}
      data-nc-id="AdsCard"
    >
      <a
        href={ad.link} // Use href for external links
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security for new tab
      >
        <img
          src={ad.image} // Assuming the image URL is prefixed
          alt={ad.title}
          className="w-full h-auto object-cover transition-transform duration-300 transform hover:scale-105"
        />
      </a>
      <div className="p-4 text-center">
        <h2 className="text-lg font-semibold text-white">{ad.title}</h2>
      </div>
    </div>
  );
};

export default AdsCard;
