import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { register } from "features/user/userSlice";
import BgGlassmorphism from "./BgGlassmorphism";
import SmallLoader from "./Dashboard/SmallLoader";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // Add state for phone number

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const errorMessage = useAppSelector((state) => state.users.error);

  function fullNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    setFullName(event.target.value);
  }

  function emailChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setEmail(event.target.value);
  }

  function passwordChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setPassword(event.target.value);
  }

  function phoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPhoneNumber(event.target.value); // Update phone number state
  }

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      // Dispatch the register action
      await dispatch(
        register({ fullName, email, password, phoneNumber })
      ).unwrap();
      navigate("/");
      // Handle successful registration
      // Redirect or update UI as needed
    } catch (error: unknown) {
      setLoading(false);
    } finally {
      setLoading(false); // End loading regardless of outcome
    }
  };

  return (
    <div className={`nc-PageSignUp relative overflow-hidden ${className}`} data-nc-id="PageSignUp">
      <BgGlassmorphism />
      <div className="container relative z-10 mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-100 justify-center">
          Sign Up
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-8">
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={submitHandler}
            >
              <label className="block">
                <span className="text-neutral-200 font-medium">
                  Full Name
                </span>
                <Input
                  type="text"
                  placeholder="Your name"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  value={fullName}
                  onChange={fullNameChange}
                />
              </label>
              <label className="block">
                <span className="text-neutral-200 font-medium">
                  Email address
                </span>
                <Input
                  type="email"
                  placeholder="example@email.com"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  value={email}
                  onChange={emailChange}
                />
              </label>
              <label className="block">
                <span className="text-neutral-200 font-medium">
                  Phone Number
                </span>
                <Input
                  type="text"
                  placeholder="+971 XX XXX XXXX"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  value={phoneNumber}
                  onChange={phoneNumberChange}
                />
              </label>
              <label className="block">
                <span className="text-neutral-200 font-medium">
                  Password
                </span>
                <Input
                  type="password"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  placeholder="••••••••"
                  value={password}
                  onChange={passwordChange}
                />
              </label>

              {errorMessage && (
                <div className="text-red-400 text-sm text-center">{errorMessage}</div>
              )}

              <ButtonPrimary
                type="submit"
                disabled={loading}
                className="w-full"
              >
                {loading ? <SmallLoader /> : "Create Account"}
              </ButtonPrimary>
            </form>
          </div>

          <span className="block text-center text-neutral-300">
            Already have an account?{" "}
            <Link to="/login" className="text-primary-500 hover:text-primary-400 font-medium">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
