// components/ProductCard.tsx
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal"; // Make sure this path is correct
import { useAppDispatch, useAppSelector } from "app/hooks";
import { addItem, removeItem } from "features/cart/cartSlice";

export interface Product {
  _id: string;
  name: string;
  image: string;
  price: number;
  description: string;
}

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { _id, name, image, price, description } = product;
  const [modalOpen, setModalOpen] = useState(false);

  const itemsInCart = useAppSelector((state) => state.cart.items);
  const dispatch = useAppDispatch();

  const isItemInCart = itemsInCart.some((item) => item.id === _id);
  const buttonLabel = isItemInCart ? "Remove" : "Add to Cart";
  const buttonColor = isItemInCart ? "bg-primary-500" : "bg-secondary-800";

  const handleCartOperation = () => {
    if (isItemInCart) {
      dispatch(removeItem(_id));
    } else {
      dispatch(
        addItem({ id: _id, type: "Product", name, image, price, quantity: 1 })
      );
    }
  };
  const toggleModal = () => setModalOpen(!modalOpen);
  const truncatedName = name.length > 25 ? `${name.substring(0, 25)}...` : name;

  return (
    <div className="flex flex-col bg-neutral-800 shadow-md rounded-lg overflow-hidden w-full max-w-sm hover:shadow-lg transition-shadow duration-300">
      <div className="relative aspect-w-16 aspect-h-9">
        <img
          src={image}
          alt={name}
          className="absolute inset-0 w-full h-full object-cover cursor-pointer p-2"
          onClick={toggleModal}
        />
      </div>
      <div className="flex-1 p-4 flex flex-col justify-between space-y-4">
        <h3
          className="text-lg font-semibold text-white cursor-pointer hover:text-gray-300 transition-colors"
          onClick={toggleModal}
        >
          {truncatedName}
        </h3>
        <div className="space-y-2">
          <label className="block text-lg font-semibold text-white">
            AED {price}
          </label>
          <ButtonPrimary
            className={`w-full ${buttonColor} hover:opacity-90 transition-opacity`}
            onClick={handleCartOperation}
          >
            {buttonLabel}
          </ButtonPrimary>
        </div>
      </div>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <div className="p-4">
          <img
            src={
              image.startsWith("http")
                ? image
                : process.env.REACT_APP_API_URL + image
            }
            alt={name}
            className="w-full h-48 object-cover"
          />
          <h2 className="text-xl font-bold p-2 text-white">{name}</h2>
          <p className="mb-4 text-white">{description}</p>
          <p className="text-lg font-semibold text-white">Price: AED {price}</p>
        </div>
      </Modal>
    </div>
  );
};

export default ProductCard;
