import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import { addItem, removeItem, clearCart } from "features/cart/cartSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

export interface Detail {
  title: string;
  content: string;
}

export interface Package {
  _id: string;
  name: string;
  price: number;
  details: Detail[];
  carType: string;
}

export interface PackageCardProps {
  className?: string;
  taxonomy: Package;
  isSelected?: boolean;
  onSelect?: () => void;
}

function determineBackground(packageName: string): string {
  switch (packageName.toLowerCase()) {
    case "silver":
      return "bg-gradient-to-r from-[#061e45] to-[#0d6ca9]";
    case "gold":
      return "bg-gradient-to-r from-[#3e0c5c] to-[#904b80]";
    case "platinum":
      return "bg-gradient-to-r from-[#7e1d25] to-[#c95d52]";
    default:
      return "bg-gray-200"; // A neutral default background
  }
}

const PackageCard: FC<PackageCardProps> = ({
  className = "",
  taxonomy,
  isSelected,
  onSelect,
}) => {
  const { _id, name, price, details } = taxonomy;
  const [modalOpen, setModalOpen] = useState(false);
  const itemsInCart = useAppSelector((state) => state.cart.items);
  const dispatch = useAppDispatch();

  const isItemInCart = itemsInCart.some((item) => item.id === _id);
  const buttonLabel = isItemInCart ? "Remove" : "Select Package";
  const buttonColor = isItemInCart ? "bg-primary-500" : "bg-secondary-800";

  const handleCartOperation = () => {
    if (isItemInCart) {
      dispatch(removeItem(_id));
    } else {
      dispatch(clearCart());
      dispatch(addItem({ id: _id, type: "Package", name, price, quantity: 1 }));
    }
  };
  const toggleModal = () => setModalOpen(!modalOpen);

  const backgroundClass = determineBackground(name);

  const textShadowClass = "text-shadow-md";

  return (
    <div
      className={`nc-CardCategory6 relative flex flex-col justify-between items-center w-full h-full rounded-2xl p-4 md:p-5 lg:p-6 ${backgroundClass} ${isItemInCart ? "ring-4 ring-yellow-400" : ""
        } ${className}`}
      data-nc-id="CardCategory6"
    >
      <div onClick={toggleModal} className="w-full">
        <h2
          className={`text-xl md:text-2xl lg:text-3xl text-white font-bold mb-4 md:mb-5 lg:mb-6 text-center ${textShadowClass}`}
        >
          {name}
        </h2>

        <div className="space-y-3 md:space-y-4">
          {details.map((detail, index) => (
            <div key={index} className="text-white">
              <h3 className="text-base md:text-lg lg:text-xl font-extrabold mb-1 md:mb-2 uppercase tracking-wider">
                {detail.title}
              </h3>
              <p className="text-sm md:text-base lg:text-lg font-medium leading-relaxed opacity-90">
                {!modalOpen
                  ? `${detail.content.substring(0, 80)}${detail.content.length > 80 ? "..." : ""
                  }`
                  : detail.content}
                {!modalOpen && detail.content.length > 80 && (
                  <button
                    className="ml-2 text-yellow-300 hover:text-yellow-400 underline font-semibold"
                    onClick={toggleModal}
                  >
                    Read More
                  </button>
                )}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6 md:mt-8 w-full flex flex-col md:flex-row lg:flex-col justify-between items-center gap-3 text-white">
        <span className={`text-xl md:text-2xl lg:text-3xl font-bold ${textShadowClass} lg:mb-4 lg:text-center lg:block lg:w-full`}>
          {price} AED
        </span>
        <ButtonPrimary
          className={`${buttonColor} px-4 py-2 md:py-3 text-sm md:text-base lg:text-lg font-semibold w-full rounded-lg`}
          onClick={handleCartOperation}
        >
          {buttonLabel}
        </ButtonPrimary>
      </div>

      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <div
          className={`p-4 md:p-6 lg:p-8 rounded-lg ${backgroundClass} max-h-[80vh] overflow-y-auto 
            w-[95vw] md:w-[80vw] lg:w-[60vw] mx-auto`}
        >
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4 md:mb-6 text-white border-b border-white/20 pb-4">
            {name} Package Details
          </h2>
          <div className="space-y-4 md:space-y-6">
            {details.map((detail, index) => (
              <div key={index} className="text-white">
                <h3 className="text-lg md:text-xl font-extrabold mb-2 md:mb-3 uppercase tracking-wider">
                  {detail.title}
                </h3>
                <p className="text-base md:text-lg font-medium leading-relaxed whitespace-pre-line">
                  {detail.content}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-4 md:mt-6 pt-4 border-t border-white/20">
            <ButtonPrimary
              onClick={toggleModal}
              className="w-full md:w-auto px-4 md:px-6 py-2 md:py-3"
            >
              Close
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PackageCard;
