// components/BlogCard.tsx
import React from "react";
import { Link } from "react-router-dom";

interface BlogCardProps {
  id: number;
  title: string;
  summary: string;
  imageUrl: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  id,
  title,
  summary,
  imageUrl,
}) => {
  return (
    <Link to={`/blog/${id}`} className="block transform transition duration-300 hover:scale-105">
      <div className="bg-neutral-800 rounded-xl shadow-lg overflow-hidden flex flex-col h-full border border-neutral-700 hover:border-blue-500">
        <div className="relative">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-56 object-cover transition duration-300 hover:opacity-90"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-neutral-900/60 to-transparent" />
        </div>
        <div className="p-6">
          <h3 className="text-xl font-bold text-white mb-3 line-clamp-2">{title}</h3>
          <p className="text-gray-300 line-clamp-3">{summary}</p>
          <div className="mt-4">
            <span className="text-blue-400 font-medium hover:text-blue-300 inline-flex items-center">
              Read More
              <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
