// ServiceManager.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assume this is already styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createService,
  deleteService,
  editService,
  fetchServices,
} from "features/service/serviceSlice";
import { uploadImage } from "utils/cloudinary";

export interface Service {
  _id: string;
  name: string;
  image: string;
  price: number;
  description: string;
}

export interface NewService {
  name: string;
  image: string;
  price: number;
  description: string;
}

const ServiceManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { services, loading, error } = useAppSelector(
    (state) => state.services
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [serviceForm, setServiceForm] = useState<NewService>({
    name: "",
    image: "", // Include the image property with an empty string
    price: 0,
    description: "",
  });

  // State to track the edited service
  const [editedService, setEditedService] = useState<Service | null>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement; // Type assertion here
    const { name, value } = target;
    // Now TypeScript knows target could have a `files` property when it's an input
    if (target.type === "file") {
      const files = target.files;
      if (files) {
        setImageFile(files[0]);
      }
    } else {
      setServiceForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
//New
  // Function to open edit modal and populate data
  const openEditModal = (serviceToEdit: Service) => {
    // Populate the serviceForm state with data from the service to edit
    setServiceForm({
      name: serviceToEdit.name,
      image: serviceToEdit.image, // Include the image property
      price: serviceToEdit.price,
      description: serviceToEdit.description,
    });

    setEditedService(serviceToEdit);
    setIsModalOpen(true);
  };




const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  console.log("Submit button clicked");

  let imageUrl = serviceForm.image;

  if (imageFile) {
    console.log("Uploading image...");
    try {
      imageUrl = await uploadImage(imageFile);
      console.log("Image URL:", imageUrl);
      console.log("Image uploaded:", imageUrl);
    } catch (error) {
      console.error("Image upload failed:", error);
      alert("Image upload failed. Please try again.");
      return; // Exit if upload fails
    }
  } else {
    console.log("No image file to upload.");
  }

  const serviceData = {
    ...serviceForm,
    image: imageUrl,
  };

  console.log("Submitting service data:", serviceData);

  if (editedService) {
    await dispatch(editService({ serviceId: editedService._id, data: serviceData }));
    console.log("Service edited:", editedService._id);
  } else {
    await dispatch(createService(serviceData));
    console.log("Service created");
  }

  closeModal();
  dispatch(fetchServices());
  console.log("Services fetched");
};

const deleteHandler = async (serviceId: string) => {
  if (window.confirm("Are you sure you want to delete this service?")) {
    const serviceToDelete = services.find(service => service._id === serviceId);

    if (serviceToDelete) {
      try {
        await dispatch(deleteService(serviceId));
        dispatch(fetchServices()); // Fetch updated services
      } catch (error) {
        console.error("Deletion failed:", error);
        alert("Failed to delete service. Please check the console for details.");
      }
    } else {
      console.error("Service not found for ID:", serviceId);
      alert("Service not found.");
    }
  }
};




  useEffect(() => {
    dispatch(fetchServices());

  }, [dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className=" p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Service</h2>
          <ButtonPrimary onClick={openModal} className="z-10">
            Create Services
          </ButtonPrimary>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Image</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
          {services.map((serviceItem: Service) => {
     
          console.log("Service image URL:", serviceItem.image);

  
          return (
              <tr key={serviceItem._id}>
                  <td className="border px-4 py-2">
                  <img
                      src={serviceItem.image}
                      alt={serviceItem.name}
                      className="h-10 w-10"
                  />

                  </td>
                  <td className="border px-4 py-2">{serviceItem.name}</td>
                  <td className="border px-4 py-2">{serviceItem.price}</td>
                  <td className="border px-4 py-2">{serviceItem.description}</td>
                  <td className="border px-4 py-2">
                      <button className="text-blue-500 hover:text-blue-700 mr-2" onClick={() => openEditModal(serviceItem)}>
                          Edit
                      </button>
                      <button className="text-red-500 hover:text-red-700" onClick={() => deleteHandler(serviceItem._id)}>
                          Delete
                      </button>
                  </td>
              </tr>
          );
        })}


      </tbody>

        </table>
        {loading && <p>Loading services...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">Create Service</h2>
              {/* Name input */}
              <div className="mb-4">
                <label className="block mb-2">Name</label>
                <Input
                  name="name"
                  placeholder="Service Name"
                  value={serviceForm.name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Price input */}
              <div className="mb-4">
                <label className="block mb-2">Price</label>
                <Input
                  name="price"
                  type="number"
                  placeholder="Service Price"
                  value={serviceForm.price}
                  onChange={handleInputChange}
                />
              </div>
              {/* Image input - assuming URL for simplicity */}
              <div className="mb-4">
                <label className="block mb-2">Image URL</label>
                <Input
                  name="image"
                  placeholder="Upload Image"
                  type="file"
                  onChange={handleInputChange}
                />
              </div>
              {/* Description textarea */}
              <div className="mb-4">
                <label className="block mb-2">Description</label>
                <textarea
                  name="description"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Service Description"
                  value={serviceForm.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ServiceManager;
