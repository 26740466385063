import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  clearSuccessMessage,
  createPartner,
  clearError,
} from "features/partner/partnerSlice";
import { Bounce, ToastContainer, toast } from "react-toastify";
import BgGlassmorphism from "./BgGlassmorphism";

const Partner: React.FC = () => {
  const dispatch = useAppDispatch();
  const { partners, loading, error, successMessage } = useAppSelector(
    (state) => state.partners
  );

  if (successMessage) {
    toast("Your details are sent to Carveel, You'll be contacted shortly!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
  });

  useEffect(() => {
    return () => {
      dispatch(clearSuccessMessage());
      dispatch(clearError());
    };
  }, [dispatch]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(createPartner(formData));
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />
      <div className="container relative mx-auto p-4 max-w-4xl z-10">
        <div className="text-center mb-12">
          <h1 className="text-4xl lg:text-5xl font-semibold py-4 text-white">Become a Partner</h1>
          <p className="mt-4 text-neutral-200">
            Join our network of trusted service providers!
          </p>
        </div>

        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 mb-8">
          <p className="text-neutral-200 mb-4">
            If you offer car services and want to expand your reach, we invite you
            to partner with us. By joining our network, you'll have the
            opportunity to connect with more customers and grow your business.
          </p>
          <p className="text-neutral-200 mb-4">
            Please fill out the form below with your details, and we will get in
            touch with you shortly.
          </p>
          <p className="text-white font-bold">
            Partner with us today and take your business to the next level!
          </p>
        </div>

        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-6">
          <p className="mb-6 font-semibold text-white text-xl">Contact Information:</p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-neutral-200 mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full bg-white/5 border border-neutral-500 rounded-xl p-4 text-white focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <label className="block text-neutral-200 mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full bg-white/5 border border-neutral-500 rounded-xl p-4 text-white focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label className="block text-neutral-200 mb-2">Phone Number</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full bg-white/5 border border-neutral-500 rounded-xl p-4 text-white focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all"
                placeholder="Enter your phone number"
              />
            </div>
            <div>
              <label className="block text-neutral-200 mb-2">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full bg-white/5 border border-neutral-500 rounded-xl p-4 text-white focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all"
                placeholder="Enter your address"
              />
            </div>

            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}

            <ButtonPrimary
              type="submit"
              className="w-full text-lg font-semibold py-4"
            >
              {loading ? "Submitting..." : "Submit Application"}
            </ButtonPrimary>
          </form>
        </div>

        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 mt-8">
          <h2 className="text-2xl font-semibold text-white mb-6">Why Partner with Us?</h2>
          <ul className="space-y-4 text-neutral-200">
            <li className="flex items-center">
              <span className="text-primary-500 mr-2">✓</span>
              Access to a broader customer base
            </li>
            <li className="flex items-center">
              <span className="text-primary-500 mr-2">✓</span>
              Increased visibility for your services
            </li>
            <li className="flex items-center">
              <span className="text-primary-500 mr-2">✓</span>
              Collaborative growth opportunities
            </li>
            <li className="flex items-center">
              <span className="text-primary-500 mr-2">✓</span>
              Dedicated support from our team
            </li>
          </ul>
          <p className="mt-6 text-white text-center font-semibold">
            We look forward to collaborating with you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Partner;
