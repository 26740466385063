// src/utils/cloudinary.ts
import axios from 'axios';

export const uploadImage = async (file: File) => {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME as string;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string;
  console.log("Cloud Name:", cloudName, "Upload Preset:", uploadPreset);


  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', uploadPreset);

  try {
      const response = await axios.post(url, formData, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      });

      return response.data.secure_url;
  } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
  }
};


  