import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useEffect } from "react";
import { fetchPartners, createPartner } from "features/partner/partnerSlice";
interface Partner {
  _id?: string;
  name: string;
  email: string;
  mobile: string;
  address?: string;
}

interface PartnerState {
  partners: Partner[];
  loading: boolean;
  error: string | null;
}

const PartnerManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { partners, loading, error } = useAppSelector(
    (state) => state.partners
  );
  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);
  console.log(partners);

  return (
    <div className="p-5">
      <div className="container-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Partners</h2>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Mobile</th>
            <th className="px-4 py-2">Address</th>
          </thead>
          <tbody>
            {partners.map((partner: Partner) => (
              <tr key={partner._id}>
                <td className="border px-4 py-2">{partner.name}</td>
                <td className="border px-4 py-2">{partner.email}</td>
                <td className="border px-4 py-2">{partner.mobile}</td>
                <td className="border px-4 py-2">{partner.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PartnerManager;
